











































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import Invoices from '@/components/archive/Invoices.vue';
import Graph from '@/components/Graph.vue';
import TrafficLight from '@/components/TrafficLight.vue';
import NotificationListItem from '@/components/NotificationListItem.vue';

import { NotificationService } from '@/lib/services';
import { Notification } from '@/models';
import { take } from 'lodash-es';
import { unwrapError } from '@/lib/helpers';

const sSettings = namespace('settings');

@Component({
  components: {
    TrafficLight,
    Invoices,
    Graph,
    NotificationListItem,
  },
})
export default class DashboardPage extends Vue {
  notifications: Notification[] = [];
  @sSettings.Getter('hasAnalytics') hasAnalytics: boolean;

  async mounted(): Promise<void> {
    try {
      this.notifications = await NotificationService.all();
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.load.notifications'),
        unwrapError(e),
      );
    }
  }

  get notificationSample(): Notification[] {
    return take(this.notifications, 2);
  }
}
